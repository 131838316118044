.sidebar {
  position: fixed;
  top: 2px;
  bottom: 0;
  left: 0;
  padding: 0;
  //box-shadow: 5px -5px 10px rgba(black, .125);
  border: 1px solid $gray-400;
  max-width: $sidebar-max-width;
  z-index: 1040;
  background: white;
  .site-logo {
    padding: 0.5rem 1.25rem;
    margin-bottom: 1rem;
  }
  .nav-item {
    display: block;
    width: 100%;
  }
  .nav-link {
    color: $body-color;
    font-size: 1rem;
    padding: ($nav-link-padding-y / 2) $nav-link-padding-x;
    padding-left: $nav-link-padding-x * 1.25;
    display: block;
    &.active {
      background: $gray-300;
    }
  }
  .collapse-toggle {
    @include caret(down);
    position: relative;
    &:after {
      position: absolute;
      left: 0;
      bottom: $nav-link-padding-y;
    }
    &.collapsed {
      @include caret(right);

    }
  }
  .nav-sub {
    padding:$nav-link-padding-y 0;
    box-shadow:
        inset 0px 10px 6px -10px rgba(black, 0.25),
        inset 0px -10px 6px -10px rgba(black, 0.25);
    background: rgba(black, 0.02);

    .nav-link {
      //color: $navbar-dark-color;
      &.active {
        background: rgba(black, 0.05);

      }
    }
  }
}

.sidebar-sticky {
  position: sticky;
  padding-top: 0.5rem;
}

.sidebar-right {
  border-left: 1px solid $border-color;
  background: white;
  @include gradient-y-three-colors(white, white, 80%, $body-bg);
  margin-top: -$main-offset-top;
  padding-top: $main-offset-top;
  @include media-breakpoint-up(lg) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 2rem;
    padding-right: 3rem;
  }
  .entry {
    position: relative;
    .entry-icon {
      position: absolute;
      right: 0;
      top: 1rem;
      font-size: 1.3rem;
    }
  }
}