.card {
  box-shadow: 3px 3px 12px 0 rgba(black, 0.3);

}

a.card {
  &:hover {
    text-decoration: none;
    box-shadow: 3px 3px 15px 0 rgba(black, 0.6);
  }

  overflow: hidden;
  color: inherit;
}

.card-sm {
  .card-body {
    padding: $card-spacer-x / 2;
  }

  .card-footer {

    padding: ($card-spacer-y / 1.5) ($card-spacer-x / 2);
  }
}

.card-dark {

}

.gallery .card-sm {
  .card-footer {
    background: $dark;
    color: white;
  }

  border: none;
  &:hover {
    @include gradient-y(lighten($dark, 45%), lighten($dark, 30%));
    @include gradient-directional(lighten($dark, 35%), lighten($dark, 50%), 160deg);

  }
}

.card-dark {
  @include gradient-directional(lighten($dark, 45%), lighten($dark, 60%), 160deg);
  &:hover {
    @include gradient-y(lighten($dark, 45%), lighten($dark, 30%));
    @include gradient-directional(lighten($dark, 35%), lighten($dark, 50%), 160deg);

  }
}

.cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @include media-breakpoint-up(md) {
    //grid-template-columns: 1fr 1fr;
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: 1fr 1fr 1fr;
  }

}

.asset-bundle-item {
  .img-thumbnail {
    max-height: 205px;
  }
  margin: 1.5rem !important;
}

.card-header {
  //font-weight: 200;
  font-size: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.card-title {
  font-size: 1.33rem;
  font-weight: normal;
}

.card-header.bg-dark {
  color: white;
}

.list-icons-grid {
  display: flex;
  flex-wrap: wrap;
  li {
    flex-basis: 50%;
    @include media-breakpoint-up(xl) {
      flex-basis: 25%;

    }
    text-align: center;
    margin-bottom: 1rem;
  }
  a {
    color: $body-color;
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
  }
  $colors: $orange-light, $red, $blue-light, $green, $gray, $yellow;

  @for $i from 1 through length($colors) {
    li:nth-child(#{length($colors)}n+#{$i}) {
      .shortcut-icon {
        background: nth($colors, $i);
      }
      a:hover {
        .shortcut-icon {
          background: darken(nth($colors, $i), 10%);
        }
      }
    }
  }
}
.shortcut {
  font-size: 0.8rem;
}

.shortcut .shortcut-icon {
  color: white;
  display: flex;
  margin: auto;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 1.7rem;
  margin-bottom: 4px;

}

.card-warning {
  border-left: 3px solid $warning;
}

.card-danger {
  border-left: 3px solid $danger;
}
.card-info {
  border-left: 3px solid $info;
}

.card-label {
  background: $primary;
  color: white;
  position: absolute;
  right: 4px;
  bottom: 4px;
  font-size: $font-size-sm;
  padding: ($card-spacer-x * 0.2) $card-spacer-x * 0.5;
}
