.subtable {
  opacity: 0.5
}

.card-stat {
  box-shadow: 0.25rem 0.25rem 0.75rem rgba($gray-dark, 0.125);
}

.card-stat-border-orange {
  border-left: 3px solid $orange-light;
}
.card-stat-label {
  font-size: 1rem;
  text-align: center;

}
.card-stat-value {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;

}
