//$body-bg: silver;
$orange: #b46a1f;
$orange-light: #f1a41e;
$red: #dc5458;
$blue: #105992;
$blue-light: #4b99f0;
$green: #799900;
$tan: #D0D8A5;
$yellow: #d1b400;
//$red: #E15428;
$gray-dark: #383838;
$gray: #a7a9ab;
$gray-light: #f7f7f7;
$white: #fff;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$body-bg: $gray-100;

$light: $gray-light;
$primary: $green;
$secondary: $orange;

$dark: $gray-dark;

$main-offset-top: 5rem;

$sidebar-max-width: 209px;
$navbar-dark-color: rgba($white, .8);

$nav-link-padding-y: 0.75rem;
$font-size-base: 0.9rem;

//$card-bg: rgba($black, .02);
$card-cap-bg: rgba($black, .125);
$card-cap-bg: transparent;

$card-border-width: 0;
//$navbar-brand-font-size: 1rem;

$thumbnail-padding: 0;
$thumbnail-border-radius: 5px;
$thumbnail-border-width: 0px;
$thumbnail-box-shadow: 0 1px 2px rgba($black, .1);

$enable-shadows: true;
$enable-gradients: false;
$enable-rounded: false;
$enable-shadows: false;