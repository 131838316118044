.header {
  .navbar {
    border-top: 2px solid $green;
    padding: 0;
    &:before {
      content: '';
      background: red;
      @include gradient-x(lighten($green, 15%), darken($green, 5%));
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 100%;
    }
  }
  .navbar-search {
    margin-left: $sidebar-max-width + ($grid-gutter-width / 2);
    display: flex;
    flex-direction: row;
    width: 100%;
    input.form-control-dark {
      transition: all 0.125s;
    }
    .btn-dark {
      background: darken($dark, 10%);
      @include button-variant(darken($dark, 10%), darken($dark, 11%));

    }
  }
}
.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.navbar-brand {
  max-width: $sidebar-max-width;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.stickyStatus {
  position: fixed;
  top: 3.5rem;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
  .callout {
    padding: 0.5rem 2rem;
  }
}