

.card-img-fill {
  overflow: hidden;
  width: 100%;
  position: relative;
  //height: 200px;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  img {
    //flex-shrink: 0;
    max-width: 100%;
    max-height: 100%;
    //border: 1px solid $gray-500;
  }
  .card-img-fill-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.facet-results-cards {
  .gallery {
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;

    .card {
      float: left;
      width: 32%;
      margin-right: 1%;
      margin-bottom: 1%;
      @supports (display:grid) {
        width: auto;
        margin: 0;
      }
    }

    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1100px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1450px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1660px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    grid-gap: 0.75rem;
  }
  .item-footer {
    text-align: center;
    .ais-hit--title {
      font-size: $font-size-sm;
      margin-bottom: 0;
    }
    .ais-hit--size {
      font-size: 0.75em;
      margin-top: 0.5em;
      margin-bottom: 0;
      font-weight: bold;
    }
  }
}

.axios-highlight {
  background: $warning;
}

.facet-results-list {
  .facet-results-item {
    margin-bottom: 1rem;
  }
}

.facet-results {
  margin-bottom: 1rem;
}

.facet-results-grid,
.facet-results-justifiedGallery {

  .ais-hits {
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr;
    //grid-gap: 0.5rem;
    //grid-auto-rows: 1fr;

    display: flex;
    flex-wrap: wrap;
  }
  .ais-hits--item {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 33.3333%;
    }
    @include media-breakpoint-up(xl) {
      width: 25%;
    }
    display: flex;
    padding: 0 0.5em 1em;
    .card {
      width: 100%;
    }
    .card-body {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .card-footer {
      font-size: $font-size-sm;
      //background: transparent;
      padding: 0.5rem;
      color: $body-color;
      //background: $card-cap-bg;
      text-align: center;
      button {
        margin-top: 0.5rem;
      }
    }

  }
  .ais-hit--title {
    font-size: $font-size-sm;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-body {
    .thumbnail {
      margin: 0 auto;
    }
  }
}

.facets-inline {
  display: flex;
  align-items: flex-end;
  .refinement-list,
  .search-box {
    margin-right: 0.5rem;
    width: 200px;
  }
}

.facet-hits-meta {
  //text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
  margin-bottom: 1rem;
  font-size: $font-size-sm;
  .form-control:not([size]):not([multiple]) {
    font-size: $font-size-sm;
    height: 2rem;
  }
  .search-box {
    margin-right: 0.5rem;
  }


}
.facet-hits-meta--right {
  display: flex;
}

.ais-stats {
  font-weight: bold;
  margin-right: 0.25rem;
  color: $text-muted;
  margin-bottom: 1rem;
  .ais-stats--time {
    font-weight: normal;
  }
}

.ais-search-box {
  position: relative;
  .ais-search-box--magnifier {
    position: absolute;
    fill: #bfc7d8;
    left: 12px;
    top: calc(50% - 18px / 2);

  }
  .ais-search-box--magnifier svg {
    width: 18px;
    height: 18px;
  }
  .ais-search-box--reset {
    position: absolute;
    fill: #bfc7d8;
    right: 12px;
    top: calc(50% - 22px / 2);
    background: none;
    margin: 0;
        border: 0;
        padding: 0;
        user-select: none;
  }
  .ais-search-box--reset svg {
    width: 12px;
    height: 12px;
  }
  .form-control {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.facet-item-count {
  color: $info;
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }
}

.ais-filters {
  top: 80px;
}

.ais-header {
  //font-size: $font-size-lg;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.ais-show-more {
  font-size: $font-size-sm;
  cursor: pointer;
}
.ais-show-more__inactive {
  &:before {
    content: "+ ";
    font-weight: bold;
  }
}
.ais-show-more__active {
  &:before {
    content: "- ";
    font-weight: bold;
  }
}

.ais-clear-all {
  margin-top: 1rem;
}



.ais-refinement-list--label {
  font-size: $font-size-sm;
  position: relative;
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;
  .badge {
    position: relative;
    top: -1px;
  }
  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    top: 2px;
    left: 0;
  }
}
.ais-search-box {

}

.ais-refinement-list {
  text-align: left;
}

.ais-menu-select {
  text-align: left;
}

.ais-search-box--input {

}
.ais-refinement-list__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;

}

.facet-results-audioFiles .gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
}

.justified-gallery a.gallery-item {
  display: block;
}