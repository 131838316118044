.media {
  .img-thumbnail {
  }
}
.media-body {
  .entry-title {
    font-size: 1rem;


  }
  .entry-meta {
    font-size: 0.8rem;
  }
}