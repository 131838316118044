.upvote-wrap {
  display: inline-block;
  padding-left: 0.5rem;
  font-size: 0.75em;
  position: relative;
  top: -0.125rem;
}
.upvote-tally-wrap {
  font-size: 1rem;
}
.upvote-vote {
  color: $gray-400;
  //font-size: 1.5rem;
}

.upvote-vote-match {
  color: lighten($yellow, 10%);
}